import { useTranslation } from 'react-i18next';
import styles from './ItinerarySolutions.module.scss';
import layoutStyles from '../../CurrentMission.module.scss';
import Button from '@components/Button/Button';

import Itinerary from '@components/Itinerary/Itinerary';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import ItineraryMap from './ItineraryMap/ItineraryMap';
import { MapWrapper } from '@components/GoogleMap/Map';
import CurrentMissionVerticalSteps from '../../CurrentMissionVerticalSteps';
import FilterButton from '@components/FilterButton/FilterButton';
import FilterModal from 'src/Pages/_Components/Filter/FilterV3/FilterModal/FilterModal';
import useItinerarySolutions from './useItinerarySolutions';
import { Oval } from 'react-loader-spinner';
import AlertBox from '@components/AlertBox/AlertBox';
import HeaderV2 from 'src/layout/Header/V2/HeaderV2';
import { useCurrentMission } from 'src/Stores/CurrentMission';

export default function ItinerarySolutions() {
  const { t } = useTranslation();
  const itinerarySolutions = useItinerarySolutions();
  const { isCurrentStepReadOnly } = useCurrentMission();

  if (!itinerarySolutions) {
    return null;
  }

  const {
    alerts,
    mission,
    extendSearchCallAction,
    extendActionLoading,
    title,
    titleClass,
    searchRadius,
    solutions,
    solutionsCount,
    points,
    selected,
    loading,
    stepIsReadOnly,
    isFilteredByPoi,
    remainingEnvelopeAmount,
    getRemainingEnvelopeText,
    onSelectItinerary,
    onSubmit,
    displayOnlyTop3,
    setDisplayOnlyTop3,
    solutionsToDisplay,
    idsFiltered,
    appliedFilterValues,
    countNbFiltersApplied,
    resetFilters,
    onClickButtonFilter,
    modalProps,
    setSolutionIdsActivated,
  } = itinerarySolutions;

  return (
    <MapWrapper>
      <FilterModal {...modalProps} />

      <PageContainer
        containerProps={{
          className: [layoutStyles.container].join(" ")
        }}
      >
        <HeaderV2 />

        <div data-testid="step-solutions" className={layoutStyles.content}>
          <CurrentMissionVerticalSteps displayBackButton />

          <div className={styles.mapContainer}>
            <div className={styles.sectionHeader}>
              <div className={styles.sectionHeaderTitle}>
                {t('foundPointsWithSolutions', {
                  count: points.length,
                  plural: points.length > 1 ? 's' : '',
                  solutionsCount: solutionsCount,
                  pluralSolutions: solutionsCount > 1 ? 's' : ''
                })}
              </div>
            </div>

            <ItineraryMap
              searchRadius={searchRadius}
              solutions={solutions}
              onPointSelected={point => {
                if (point) {
                  setSolutionIdsActivated(point.solutionsIds);
                } else {
                  setSolutionIdsActivated([]);
                }
              }}
            />

            {extendSearchCallAction && !isCurrentStepReadOnly() && (
              <div className={`flex flex-row items-center ${styles.expandSearchButtonContainer}`}>
                <>
                  <Button
                    type="secondary"
                    label={t('extend_search')}
                    containerProps={{
                      className: styles.expandSearchButton,
                      disabled: extendActionLoading || stepIsReadOnly
                    }}
                    onClick={extendSearchCallAction}
                  />
                  {extendActionLoading && (
                    <Oval height={40} width={40} strokeWidth={5} strokeWidthSecondary={5} />
                  )}
                </>

                {searchRadius && (
                  <div className={`flex ${styles.searchRadiusContainer}`}>
                    {`${t('searchRadiusLabel')} ${(searchRadius / 1000).toFixed(1)} km`}
                  </div>
                )}
              </div>
            )}

          </div>

          <div className={styles.resultsContainer}>
            <div className={styles.sectionHeader}>
              <div className={`${styles.sectionHeaderTitle} ${titleClass}`}>
                {title}
              </div>
              <div className="flex flex-row items-center">
                {!isCurrentStepReadOnly() && (
                <FilterButton
                  onClick={onClickButtonFilter}
                  countNbFiltersApplied={countNbFiltersApplied}
                />
                )}
              </div>
            </div>

            {alerts.map((alert, i) => (
              <AlertBox
                key={i}
                displayIcon
                type={alert.severity}
                containerProps={{
                  style: {
                    marginBottom: '24px'
                  }
                }}
                text={alert.texts}
              />
            ))}

            <div className={styles.results}>
              {solutionsToDisplay.length === 0 && (
                <>
                  <div className={styles.noResults}>
                    {t('no_results_explanation')}
                  </div>
                  <Button
                    type="secondary"
                    label={t('resetFiltersLabel')}
                    onClick={() => {
                      resetFilters({
                        noRemainingCosts: false,
                      })
                    }}
                  />
                </>
              )}

              {solutionsToDisplay.map((solution, i) => {
                let title = `${t('from')} ${solution.mainOrigin.name}`;
                let title2: string | undefined = undefined;
                if (solution.mainOrigin?.city) {
                  title = `${t('from')} ${solution.mainOrigin.name}`;
                  title2 = `${solution.mainOrigin.city ? " " + solution.mainOrigin.city : ""}`;
                }

                return (
                  idsFiltered[solution.id] ? (
                    <Itinerary
                      key={solution.id}
                      testid={`itinerary-${i}`}
                      readOnly={stepIsReadOnly}
                      title={
                        isFilteredByPoi && solution.mainDestination
                          ? `${t('towards')} ${solution.mainDestination.name}`
                          : title
                      }
                      title2={isFilteredByPoi ? undefined : title2}
                      departureDateTimeIso={solution.departureDatetime}
                      arrivalDateTimeIso={solution.arrivalDatetime}
                      durationInMinutes={solution.durationInMinutes}
                      dataByEnabledSections={solution.dataByEnabledSections}
                      price={{
                        amount: solution.price.amount,
                        formatted: solution.price.formatted,
                        currency: {
                          symbol: solution.price.currency.symbol,
                          code: solution.price.currency.code
                        }
                      }}
                      selected={selected === i}
                      onSelect={onSelectItinerary}
                      onSelectParams={{
                        index: i
                      }}
                      containerProps={{
                        className: styles.itinerary
                      }}
                      mainTransportType={solution.mainTransportType.id}
                      sections={solution.sections}
                      co2Emission={
                        solution.kgCO2e
                          ? {
                            value: solution.kgCO2e,
                            level: solution.ecologyRating
                          }
                          : undefined
                      }
                      remainingCosts={solution.remainingCosts}
                      transportations={solution.sections.map(section => section.type)}
                      tags={solution.tags}
                      detailedTags={solution.detailedTags}
                      showRemainingCosts={appliedFilterValues.hasRemainingCosts as boolean}
                    />
                  ) : null
                );
              })}
            </div>

            {!isCurrentStepReadOnly() && displayOnlyTop3 && solutions.length > 3 && (
              <div
                onClick={() => {
                  setDisplayOnlyTop3(false);
                }}
                className={styles.displayMoreButton}
              >
                {t('displayMoreButton')}
              </div>
            )}
          </div>
        </div>

        {!isCurrentStepReadOnly() && (
        <div className={layoutStyles.footer}>
          <div />
          <div className={styles.footerBtnContainer}>
            {mission.guaranteeDetails?.isEnvelope && remainingEnvelopeAmount && (
              <div
                className={`${styles.envelope} ${remainingEnvelopeAmount.amount === 0 ? styles.isNegative : ''
                  }`}
              >
                {getRemainingEnvelopeText()}
              </div>
            )}
            <Button
              testid="button-submit"
              containerProps={{
                disabled: selected === null || loading || stepIsReadOnly
              }}
              label={extendActionLoading || loading ? t('loadingLabel') : t('validate')}
              onClick={() => {
                onSubmit();
              }}
            />
          </div>
        </div>
        )}
      </PageContainer>
    </MapWrapper>
  );
}
