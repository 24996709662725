import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './FeedbackWithStars.module.scss';
import { Star, StarSelected } from '@assets/icons/icons';
import Button from '@components/Button/Button';
import { Oval } from 'react-loader-spinner';

export default function FeedbackWithStars({
  loading = false,
  score = null,
  onClickStar,
  containerProps: { className, ...containerProps } = {},
}: {
  loading?: boolean;
  score?: number | null;
  onClickStar?: (star: number | null) => void;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  const stars = [1, 2, 3, 4, 5];
  const [selectedStar, setSelectedStar] = React.useState<number | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (score) {
      setSelectedStar(score);
    }
  }, [score])


  const handleSelectStar = (star: number) => {
    if (loading || score ) {
      return;
    }
    setSelectedStar(star);
  }

  const handleValidateChoice = () => {
    onClickStar?.(selectedStar);
  }

  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>

      <div className={styles.text}>
        {t('feedback_question')}
      </div>

      <div className={styles.starsContainer}>
        {stars.map((star) => (
          <div
            key={star}
            className={[
              styles.starContainer,
              selectedStar !== null && selectedStar >= star ? styles.selected : "",
              score ? styles.readonly : "" 
            ].join(" ")}
            onClick={() => handleSelectStar(star)}
          >
            {selectedStar !== null && selectedStar >= star ? <StarSelected /> : <Star />}
          </div>
        ))}
      </div>

      {score && (
      <div className={styles.confirmText}>
        {t('feedback_confirmed')}
      </div>
      )}

      {selectedStar && !score && (
      <div className={styles.confirmButton}>
        <Button
          size="small"
          type="primary"
          onClick={() => handleValidateChoice()}
          containerProps={{ disabled:loading, className: styles.button }}
        >
          {loading ? (
            <Oval
              height={20}
              width={20}
              color='white'
              secondaryColor='white'
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            t("send")
          )}
        </Button>
      </div>
      )}


    </div>
  )
}