import InputText from '@components/Inputs/InputText/InputText';
import Select from '@components/Select/Select';
import styles from './PhoneNumberInput.module.scss';
import { NumericFormat, PatternFormat } from 'react-number-format';
import rawCountries from './rawCountries.js';

import countries from 'i18n-iso-countries';
import countriesEn from "i18n-iso-countries/langs/en.json";
import countriesFr from "i18n-iso-countries/langs/fr.json";
import { useTranslation } from 'react-i18next';

countries.registerLocale(countriesEn);
countries.registerLocale(countriesFr);

export default function PhoneNumberInput({
  label,
  placeholder,
  errorMsg,
  testid,
  value: rawValue,
  disabled,
  onChange,
  containerProps: { className, ...containerProps } = {},
}: {
  label?: React.ReactNode;
  placeholder?: string;
  errorMsg?: string;
  testid?: string;
  value?: string;
  disabled?: boolean;
  onChange?: (value: string) => any;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n?.language?.split('-')?.[0];
  const [selectedCountryCode, countryCallingCode, value] = rawValue ? rawValue.toLowerCase().split(" ") : [null, null, ""];

  const selectedCountry = rawCountries.find((country) => {
    return country[2] === selectedCountryCode;
  }) || null;

  const mask: string|null = (selectedCountry && selectedCountry.length > 4) ? selectedCountry[4] as string : null;

  const onChangeValue = (value: string, countryCode: string | null, countryCallingCode: string | null) => {
    onChange && onChange((countryCode || "") + " " + (countryCallingCode || "") + " " + (value || ""));
  }

  return (
    <div data-testid={testid} className={[styles.container, disabled ? styles.disabled : "", className].join(" ")} {...containerProps}>

      {(label || errorMsg) && (
        <div className={styles.labelContainer}>
          {label && <div className={styles.label}>{label}</div>}
          {errorMsg && <div data-testid="errorMsg" className={styles.errorMsg}>{errorMsg}</div>}
        </div>
      )}

      <div className='flex w-full flex-row items-end justify-between'>
        <Select
          disabled={disabled}
          optionsV2={rawCountries.map((country) => {
            const countryName = countries.getName(country[2] as string, currentLanguage);
            return {
              value: "+" + country[3] + " " + countryName,
              displaySelectedValue: "+" + country[3],
              searchValue: countryName as string,
            };
          })}
          selectedIndex={selectedCountryCode ? rawCountries.findIndex((country) => {
            return country[2] === selectedCountryCode;
          }): null}
          onChangeIndex={(index) => {
            onChangeValue(value || "", index !== null ? rawCountries[index][2] as string : null, index !== null ? "+" + rawCountries[index][3] as string : null);
          }}
          containerProps={{
            className: "mr-2 w-32",
          }}
        />
        <InputText
          disabled={disabled}
          inputComponent={mask ? (
            <PatternFormat
              format={mask.replace(/\./g, "#")}
              mask="_"
              placeholder={placeholder || mask.replace(/\./g, "_")}
              value={value}
              valueIsNumericString={true}
              onValueChange={(values) => {
                if (selectedCountryCode === 'fr' && values.value.length > 1 && values.value[0] === "0") {
                  values.value = values.value.slice(1);
                }
                onChangeValue(values.value, selectedCountryCode, countryCallingCode);
              }}
            />
          ) : (
            <NumericFormat
              value={value}
              onChange={(e) => {
                onChangeValue(e.target.value, selectedCountryCode, countryCallingCode);
              }}
            />
          )}
          containerProps={{
            className: "w-full",
          }}
        />
      </div>
    </div>
  )
}