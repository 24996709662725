import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import styles from './SearchMissionsPage.module.scss';
import InputText from '@components/Inputs/InputText/InputText';
import Button from '@components/Button/Button';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { authenticatedApi } from 'src/Api/api';
import { useNavigate } from 'react-router-dom';
import SearchMissionsResults from './SearchMissionsResults/SearchMissionsResults';
import { useDebounce } from 'usehooks-ts';
import PhoneNumberInput from '@components/PhoneNumberInput/PhoneNumberInput';
import { useTranslation } from 'react-i18next';
import { MissionSearchResult } from 'src/models/Mission';
import Select from '@components/Select/Select';
import DateRangePicker from '@components/DateRangePicker/DateRangePicker';
import HeaderV2 from 'src/layout/Header/V2/HeaderV2';


function useStateWithDebounce<T>(value: T, delay: number): [T, Dispatch<SetStateAction<T>>, T] {
  const [state, setState] = useState<T>(value);

  const debouncedValue = useDebounce(state, delay);

  return [
    state,
    setState,
    debouncedValue
  ]
}

export default function SearchMissionsPage({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [_loading, setLoading, loadingDebounced] = useStateWithDebounce<boolean>(false, 150);

  const [missions, setMissions] = useState<MissionSearchResult[]>([]);

  const [caseId, setCaseId, caseIdDebounced] = useStateWithDebounce<string>("", 500);
  const [missionId, setMissionId, missionIdDebounced] = useStateWithDebounce<string>("", 500);

  const [selectedStatuses, setSelectedStatuses] = useState<{
    id: string;
    label: string;
  }[]>([
    { id: 'all', label: t('all') },
  ]);
  const [mainTransportations, setMainTransportations] = useState<{
    id: string;
    label: string;
  }[]>([
    { id: 'all', label: t('all') },
  ]);

  const [createdAtStart, setCreatedAtStart] = useState<Date | null>(null);
  const [createdAtEnd, setCreatedAtEnd] = useState<Date | null>(null);

  const [dateRange, setDateRange] = useState<[Date, Date] | null>(null);

  useEffect(() => {
    if ((createdAtStart && createdAtEnd)) {
      setDateRange([createdAtStart, createdAtEnd]);
    } else if ((!createdAtStart && !createdAtEnd)) {
      setDateRange(null);
    }
  }, [createdAtStart, createdAtEnd]);

  const [customerName, setCustomerName] = useStateWithDebounce<string>("", 500);
  const [phoneNumber, setPhoneNumber, phoneNumberDebounced] = useStateWithDebounce<string>("fr +33 ", 500);
  const [licensePlate, setLicensePlate, licensePlateDebounced] = useStateWithDebounce<string>("", 500);

  const [selectStatusOptions, setSelectedStatusOptions] = useState<{
    id: string;
    label: string;
  }[]>([]);

  const [mainTransportationOptions, setMainTransportationOptions] = useState<{
    id: string;
    label: string;
  }[]>([]);

  useEffect(() => {

    const phoneNumberSplitted = phoneNumberDebounced.split(" ")
    const hasPhone = phoneNumberSplitted.length === 3 && phoneNumberSplitted[2].length > 0;

    const mainTypes = mainTransportations.map((status) => status.id === 'all' ? null : status.id).filter((status) => status !== null);
    const statuses = selectedStatuses.map((status) => status.id === 'all' ? null : status.id).filter((status) => status !== null);

    setLoading(true);
    authenticatedApi.get("/missions", {
      params: {
        caseID: caseIdDebounced || undefined,
        missionID: missionIdDebounced || undefined,
        licensePlate: licensePlateDebounced || undefined,
        phoneNumber: hasPhone ? phoneNumberDebounced : undefined,
        minMissionCreationTime: dateRange?.[0].toISOString().split("T")[0],
        maxMissionCreationTime: dateRange?.[1].toISOString().split("T")[0],
        clientName: customerName || undefined,
        mainTypes: mainTypes.length === 0 ? undefined : mainTypes,
        statuses: statuses.length === 0 ? undefined : statuses,
      },
    }).then((res) => {
      if (!res.data?.missions || !Array.isArray(res.data.missions)) {
        setMissions([]);
        setLoading(false);
        return;
      }
      setMissions(res.data.missions as MissionSearchResult[]);
      setSelectedStatusOptions([
        {
          id: 'all',
          label: t('all')
        },
        ...(res.data.statuses as { id: string, label: string }[])
      ]);
      setMainTransportationOptions([
        {
          id: 'all',
          label: t('all')
        },
        ...(res.data.mainTypes as { id: string, label: string }[])
      ]);
      setLoading(false);
    }).catch((err) => {
      console.log("CASES ERROR ", err);
      setLoading(false);
    })

  }, [caseIdDebounced, customerName, dateRange, licensePlateDebounced, mainTransportations, missionIdDebounced, phoneNumberDebounced, selectedStatuses, setLoading, t]);

  return (
    <PageContainer
      containerProps={{
        ...containerProps,
        className: [styles.container, className].join(" "),
      }}
    >

      <HeaderV2 />

      <div className={styles.content}>

        <div className={styles.header}>

          <div className={styles.formTitleLine}>
            <div className={styles.formTitle}>{t('searchAMission')}</div>
            <Button
              label={t('button.createNewCase')}
              type="primary"
              testid='button-create-case'
              onClick={() => {
                navigate("/create-case", {
                  state: missions.length === 0 ? {
                    caseId,
                    licensePlate,
                    phoneNumber,
                  } : {},
                });
              }}
              containerProps={{
                className: styles.buttonCreateCase,
              }}
            />
          </div>

          <div className={styles.form}>
            <InputText
              label={t('maasCaseNumberLabel')}
              containerProps={{
                className: styles.input,
              }}
              testid='input-case-number'
              inputProps={{
                type: "text",
                placeholder: t('enter_file_number'),
                value: caseId,
                onChange: (e) => {
                  setCaseId(e.target.value.slice(0, 11));
                },
              }}
            />

            <InputText
              label={t('maasMissionNumberLabel')}
              containerProps={{
                className: styles.input,
              }}
              testid='input-mission-number'
              inputProps={{
                type: "text",
                placeholder: t('missionNumberInputPlaceholder'),
                value: missionId,
                onChange: (e) => {
                  setMissionId(e.target.value);
                },
              }}
            />

            <Select
              allowMultiple
              label={t('statusLabel')}
              optionsV2={selectStatusOptions.map((status) => ({
                label: status.label,
                value: status.id,
              }))}
              selectedIndexes={(selectedStatuses || []).map((status) => selectStatusOptions.findIndex((option) => option.id === status.id))}
              displayedValue={(selectedStatuses || []).map((status) => status.label).join(", ")}
              onChangeIndexes={(indexesSelected) => {

                if (indexesSelected.includes(0)) {
                  if (selectedStatuses.find((status) => status.id === 'all') === undefined) {
                    setSelectedStatuses([selectStatusOptions[0]]);
                    return;
                  }
                }

                if (selectedStatuses.find((status) => status.id === 'all') !== undefined && indexesSelected.length === 0) {
                  return;
                }

                setSelectedStatuses(indexesSelected.filter((index) => index !== 0).map((index) => selectStatusOptions[index]));
              }}
              containerProps={{
                className: styles.selectMultiple,
              }}
              valueClassName={styles.selectMultipleValue}
            />

            <Select
              allowMultiple
              label={t('mainPrestationLabel')}
              optionsV2={mainTransportationOptions.map((status) => ({
                label: status.label,
                value: status.id,
              }))}
              selectedIndexes={(mainTransportations || []).map((status) => mainTransportationOptions.findIndex((option) => option.id === status.id))}
              displayedValue={(mainTransportations || []).map((status) => status.label).join(", ")}
              onChangeIndexes={(indexesSelected) => {

                if (indexesSelected.includes(0)) {
                  if (mainTransportations.find((status) => status.id === 'all') === undefined) {
                    setMainTransportations([mainTransportationOptions[0]]);
                    return;
                  }
                }

                if (mainTransportations.find((status) => status.id === 'all') !== undefined && indexesSelected.length === 0) {
                  return;
                }

                setMainTransportations(indexesSelected.filter((index) => index !== 0).map((index) => mainTransportationOptions[index]));
              }}
              containerProps={{
                className: styles.selectMultiple,
              }}
              valueClassName={styles.selectMultipleValue}
            />

          </div>

          <div className={styles.form}>

            <DateRangePicker
              label={t('missionCreationDateLabel')}
              dateRange={[createdAtStart, createdAtEnd]}
              onChange={(dateRange) => {
                setCreatedAtStart(dateRange[0]);
                setCreatedAtEnd(dateRange[1]);
              }}
            />


            <InputText
              label={t('beneficiaryNameLabel')}
              containerProps={{
                className: styles.input,
              }}
              inputProps={{
                type: "text",
                placeholder: t('beneficiaryNamePlaceholder'),
                value: customerName,
                onChange: (e) => {
                  setCustomerName(e.target.value);
                },
              }}
            />

            <PhoneNumberInput
              label={t('mobile_phone_number')}
              value={phoneNumber}
              placeholder={t('enter_a_phone_placeholder')}
              onChange={(value) => {
                setPhoneNumber(value);
              }}
            />

            <InputText
              label={t('license_plate')}
              containerProps={{
                className: `${styles.input} ${styles.inputPassword}`,
              }}
              inputProps={{
                type: "text",
                value: licensePlate,
                placeholder: t('placeholder_enter_license_plate'),
                onChange: (e) => {
                  const value = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, "");
                  setLicensePlate(value)
                },
              }}
            />

          </div>

        </div>

        <SearchMissionsResults
          containerProps={{
            className: styles.results,
          }}
          theadClassName={styles.thead}
          caseIdCellClassName={styles.caseIdCell}
          isLoading={loadingDebounced}
          results={missions}
        />

      </div>

    </PageContainer>
  )
}