import React from 'react';
import styles from './PhoneChatModal.module.scss';
import ReactModalWrapper from '@components/Modal/ReactModalWrapper';
import Button from '@components/Button/Button';

import { ReactComponent as AssistImage } from './images/Assist.svg'
import { useCurrentMission } from 'src/Stores/CurrentMission';

export default function PhoneChatModal({
  onRequestClose,
  containerProps: { className, ...containerProps } = {},
}: {
  onRequestClose: () => void;
  containerProps?: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;
}) {

  const { mission } = useCurrentMission();

  return (
    <ReactModalWrapper
      onRequestClose={onRequestClose}
      style={{
        content: {
          width: "100%",
          height: "auto",
          bottom: 0,
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "center",
          alignSelf: "flex-end",
        }
      }}
    >
      <div className={[styles.modalContainer, className].join(" ")} {...containerProps}>

        <div className={styles.contentContainer}>
          <div className={styles.title}>Besoin d'aide ?</div>
          <AssistImage />
          <div className={styles.descriptions}>
            {mission && mission.caseID && mission.id && (
              <div className={styles.descriptionPrimary}>Votre numéro de dossier : {mission.caseID}/{mission.id}</div>
            )}
            <div className={styles.descriptionPrimary}>Nous sommes là pour vous aider.</div>
            <div className={styles.description}>Ouvert 24h/24 et 7J/7</div>
            <div className={styles.description}>Appel non surtaxé</div>
          </div>
        </div>


        <Button
          type="primary"
          label="Appeler un conseiller"
          onClick={() => {
            window.open('tel:+33978978000', '_blank');
            // onRequestClose();
          }}
          containerProps={{
            className: styles.button,
          }}
        />

        <Button
          type="secondary"
          label="Annuler"
          onClick={onRequestClose}
          containerProps={{
            className: styles.button,
          }}
        />

      </div>
    </ReactModalWrapper>
  )
}