import layoutStyles from '../../CurrentMission.module.scss';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import WarrantiesListForm from 'src/Pages/_Components/WarrantiesList/WarrantiesList';
import ContractForm from 'src/Pages/_Components/ContractForm/ContractForm';
import SearchForm, { SearchFormHandle } from 'src/Pages/_Components/SearchForm/SearchForm';
import styles from './SearchItinerary.module.scss';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import { Oval } from 'react-loader-spinner';
import { useRef, useState } from 'react';
import CurrentMissionVerticalSteps from '../../CurrentMissionVerticalSteps';
import FullScreenLoader from '@components/FullScreenLoader/FullScreenLoader';
import { isAppEnvProductionLike } from 'src/appEnv';
import SendLinkButton from 'src/Pages/_Components/SendSefCareLinks/SendLinkButton';
import useSendLinkButtons from 'src/Pages/_Components/SendSefCareLinks/useSendLinkButtons';
import { useTranslation } from 'react-i18next';
import HeaderV2 from 'src/layout/Header/V2/HeaderV2';

export default function SearchItinerary() {

  const searchFormRef = useRef<SearchFormHandle>(null);
  const { t } = useTranslation();
  const { mission, isCurrentStepReadOnly } = useCurrentMission();
  const {
    canSendRefundLink,
    canSendSelfCareLink
  } = useSendLinkButtons();

  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);

  if (!mission) {
    return null;
  }

  return (
    <PageContainer
      containerProps={{
        className: [layoutStyles.container].join(" "),
      }}
    >

      {loadingSearch && (
        <FullScreenLoader />
      )}

      <HeaderV2 />


      <div className={layoutStyles.content} data-testid="step-search-itinerary">

        <CurrentMissionVerticalSteps
          containerProps={{
            style: {
              marginTop: '96px'
            }
          }}
        />

        <div className={[styles.colFormContainer, styles.colFormLeft].join(" ")}>

          <div className={styles.formTitle}>
            {t('insurance_contract')}
          </div>

          <ContractForm
            testid='contract-form'
            editable={!isCurrentStepReadOnly()}
            onChangeEditState={({ isEditing, isLoading }) => {
              setEditing(isEditing);
              setLoading(isLoading);
            }}
            containerProps={{
              className: styles.contractForm,
            }}
          />

          <div className={styles.warrantiesListFormContainer}>
            {editing && (
              <div className={styles.overlay}>
                {loading && (
                  <Oval
                    height={40}
                    width={40}
                    color="white"
                    secondaryColor="white"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                )}
              </div>
            )}
            <WarrantiesListForm
              containerProps={{
                className: styles.warrantiesListForm,
              }}
            />
          </div>


        </div>

        <div className={styles.colFormContainer}>

          {!isAppEnvProductionLike() && (canSendRefundLink || canSendSelfCareLink) && (
            <>
              <div className={styles.formTitle}>
                {t('redirect_to_selfcare')}
              </div>

              <div className={styles.sendLinkContainer}>
                {canSendSelfCareLink && (
                  <SendLinkButton
                    type='search'
                    getAdditionnalData={async () => {
                      return await searchFormRef.current?.getFormData();
                    }}
                    containerProps={{
                      className: styles.sendLinkButton,
                      disabled: isCurrentStepReadOnly(),
                    }}
                  />
                )}

                {canSendRefundLink && (
                  <SendLinkButton
                    type='refund'
                    buttonType='secondary'
                    containerProps={{
                      className: styles.sendLinkButton,
                    }}
                  />
                )}

              </div>
            </>
          )}


          <div className={styles.formTitle}>
            {t('mobility_search')}
          </div>

          <div className={styles.searchFormContainer}>
            {editing && (
              <div className={styles.overlay}>
                {loading && (
                  <Oval
                    height={40}
                    width={40}
                    color="white"
                    secondaryColor="white"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                )}
              </div>
            )}
            <SearchForm
              ref={searchFormRef}
              readOnly={isCurrentStepReadOnly()}
              onChangeSearchLoading={(isLoading) => {
                setLoadingSearch(isLoading);
              }}
              containerProps={{
                className: [styles.searchForm].join(" "),
              }}
            />
          </div>
        </div>


      </div>

    </PageContainer>
  )
}
